import React, { Component } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { Link, navigate } from 'gatsby';
import Loader from './admin/modal-overlays/loader';
import ContactBoxes from './frontend/components/common/contact-boxes';
import { Helmet } from 'react-helmet';

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      report: {},
      isLoading: false,
    };
  }

  componentDidMount() {
    this.checkUser();
  }

  checkUser = () => {
    this.setState({ isLoading: true });
    let _self = this;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        _self.setState({ isLoading: false });
        this.changeReadStatus(user.uid);
      } else {
        _self.setState({ isLoading: false });
        localStorage.removeItem('user');
        localStorage.setItem('redirectTo', this.props.location.pathname);
        navigate('/login');
      }
    });
  };

  getReport = (uid) => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('reports')
      .doc(this.props.id)
      .get()
      .then((report) => {
        console.log(report.data());
        firebase
          .firestore()
          .collection('user_subscriptions')
          .where('userId', '==', uid)
          .where('product_id', '==', report.data().selectedProduct.value)
          .where('status.value', '==', 'active')
          .get()
          .then((snapShot) => {
            if (snapShot.docs.length === 0) {
              navigate('/dashboard');
            } else {
              _self.setState({ isLoading: false, report: report.data() });
              _self.scrollUp();
            }
          });
      })
      .catch((err) => {
        console.log(err);
        _self.setState({ isLoading: false });
      });
  };

  formatDate = (seconds) => {
    let date = new Date(seconds * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  scrollUp = () => {
    window.scrollTo(0, 0);
  };

  changeReadStatus = (uid) => {
    firebase
      .firestore()
      .collection('user_reports')
      .where('userId', '==', uid)
      .where('reportId', '==', this.props.id)
      .get()
      .then((snapShot) => {
        if (snapShot.docs.length == 0) {
          firebase
            .firestore()
            .collection('user_reports')
            .add({ userId: uid, reportId: this.props.id })
            .then((res) => {})
            .catch((e) => {
              console.log(e);
            });
        }
        this.getReport(uid);
      })
      .catch((e) => {
        console.log('e', e);
      });
  };

  scrollToHeading = (id) => {
    console.log(document.getElementById('nav-tabs')?.offsetHeight);
    let offset =
      document.getElementById('nav-tabs')?.offsetHeight + document.getElementById('header')?.offsetHeight + 2; // sticky nav height
    let el = document.getElementById(id); // element you are scrolling to
    window.scroll({ top: el.offsetTop - offset, left: 0, behavior: 'smooth' });
  };

  render() {
    const { report } = this.state;
    let report_date = report.reportDate && this.formatDate(report.reportDate.seconds);
    return (
      <>
        <Helmet>
          <link rel="stylesheet" href="/ck.css" />
        </Helmet>
        <div className="details-wrapper">
          <div className="footer-fix details-wrapper-dynamic">
            {this.state.report && (
              <div className="container pt-3">
                {report.tabsData &&
                report.tabsData.filter((item) => item.title !== '').length > 0 &&
                report.tabsData.filter((obj) => obj.status.value === 'completed').length > 0 ? (
                  <div className="nav-title-tabs-wrap" id="nav-tabs">
                    <ul className="nav-title-tabs">
                      {report.tabsData.length
                        ? report.tabsData.map((tabTitle, tabTitleKey) => (
                            <li key={tabTitleKey}>
                              {tabTitle.status.value == 'completed' && tabTitle.title !== '' ? (
                                <a href="javascript:void(0);" onClick={() => this.scrollToHeading(tabTitle.label)}>
                                  {tabTitle.label}
                                </a>
                              ) : null}
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                ) : null}

                <div id="details-wrap">
                  {report.selectedProduct && report.reportType ? (
                    <h1>
                      {report.selectedProduct.label}-{report.reportType.label}
                    </h1>
                  ) : null}
                  <p className="report-date">
                    Report Date: <span>{report_date}</span>
                  </p>
                  {report.ov_title ? <h4>{report.ov_title}</h4> : null}
                  {report.ov_description ? (
                    <div className="row custom-table">
                      <div className="col-12">
                        <div dangerouslySetInnerHTML={{ __html: report.ov_description }} />
                      </div>
                    </div>
                  ) : null}
                  <br />
                  {report.sections && report.sections.length
                    ? report.sections.map((section, secKey) => (
                        <div key={secKey} className="my-3">
                          {section.title ? <h3>{section.title}</h3> : null}
                          {section.rows && section.rows.length
                            ? section.rows.map((row, rowKey) => (
                                <div key={rowKey} className="row mx-0 my-2 custom-table">
                                  {row.columns && row.columns.length
                                    ? row.columns.map((column, colKey) => (
                                        <div className={`col-${column.colSize}`} key={colKey}>
                                          {column.colType == 'image' && column.colValue ? (
                                            <div className="img-wrap">
                                              <img src={column.colValue} />
                                            </div>
                                          ) : (
                                            <div dangerouslySetInnerHTML={{ __html: column.colValue }} />
                                          )}
                                        </div>
                                      ))
                                    : null}
                                </div>
                              ))
                            : null}
                        </div>
                      ))
                    : null}

                  {/* TABS START */}
                  <div className="front-tabs mt-3">
                    {report.tabsData && report.tabsData.length
                      ? report.tabsData.map((tab, tabKey) => (
                          <div>
                            {tab.status.value == 'completed' ? (
                              <div key={tabKey} id={tab.label} className="section-wrap">
                                {tab.title ? <h2>{tab.title}</h2> : null}
                                {tab.subtitle ? <h4>{tab.subtitle}</h4> : null}
                                {tab.description ? <div dangerouslySetInnerHTML={{ __html: tab.description }} /> : null}
                                {tab.entry || tab.stop || tab.target ? (
                                  <div className="row row-prices">
                                    {tab.entry ? (
                                      <div className="col-4">
                                        <h5>
                                          Entry: <span>{tab.entry}</span>
                                        </h5>
                                      </div>
                                    ) : null}
                                    {tab.stop ? (
                                      <div className="col-4">
                                        <h5>
                                          Stop: <span>{tab.stop}</span>
                                        </h5>
                                      </div>
                                    ) : null}
                                    {tab.target ? (
                                      <div className="col-4">
                                        <h5>
                                          Target: <span>{tab.target}</span>
                                        </h5>
                                      </div>
                                    ) : null}
                                  </div>
                                ) : null}
                                {tab.sections && tab.sections.length
                                  ? tab.sections.map((tabSection, tabSecKey) => (
                                      <div key={tabSecKey}>
                                        {tabSection && tabSection.title ? (
                                          <h5 className="section-title">{tabSection.title}</h5>
                                        ) : null}
                                        {tabSection.rows.length
                                          ? tabSection.rows.map((tabRow, tabRowKey) => (
                                              <div key={tabRowKey} className="row mx-0 my-3 custom-table">
                                                {tabRow.columns.length
                                                  ? tabRow.columns.map((column, tabColKey) => (
                                                      <div className={`col-${column.colSize}`} key={tabColKey}>
                                                        {column.colType == 'image' && column.colValue ? (
                                                          <div className="img-wrap">
                                                            <img src={column.colValue} />
                                                          </div>
                                                        ) : (
                                                          <div dangerouslySetInnerHTML={{ __html: column.colValue }} />
                                                        )}
                                                      </div>
                                                    ))
                                                  : null}
                                              </div>
                                            ))
                                          : null}
                                      </div>
                                    ))
                                  : null}
                              </div>
                            ) : null}
                          </div>
                        ))
                      : null}
                  </div>
                  {/* TABS END */}
                </div>
                <button onClick={this.scrollUp} className="scroll-up">
                  &#8679;
                </button>
              </div>
            )}

            {report.product_disclaimer ? (
              <div className="container">
                <h5>Product Disclaimer</h5>
                <div dangerouslySetInnerHTML={{ __html: report.product_disclaimer }} />
              </div>
            ) : null}

            <div className="container" id="contact-us">
              <ContactBoxes />
            </div>
            <Loader isOpen={this.state.isLoading} />
          </div>
        </div>
      </>
    );
  }
}

export default Reports;
